<template>
  <baidu-map v-if="initMapFlag" class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="scrollWheelZoom">
    <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
    <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
    <bm-overview-map anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :isOpen="true"></bm-overview-map>
    <template v-if="flag">
      <bm-marker v-for="m in polylinesArr" :key="`m_${m.id}}`" :position="getCenter(m)" :dragging="false" @click="showInfoWindow(m)"></bm-marker>
      <template v-if="showCall">
        <div>
          <bm-polyline v-for="p in polylinesArr" :key="`p_${p.id}`" :path="p.gpsList" stroke-color="red" :stroke-opacity="0.5" :stroke-weight="2"></bm-polyline>
        </div>
      </template>
    </template>
    <bm-info-window v-if="showCall" :position="winPos" :offset="windowOffset" :show="infoWindowFlag" @clickclose="closeWindow" :closeOnClick="false">
      <div class="call-box">
        <p class="name">{{ userInfo.realName }}</p>
        <p>
          <img :src="userInfo.avatar" alt="">
        </p>
        <el-button class="call-btn" type="primary" size="mini" @click="mapCall">呼叫</el-button>
      </div>
    </bm-info-window>
  </baidu-map>
</template>

<script>
export default {
  name: '',
  props:['initMap','showCall','polylinesArr','scrollWheelZoom'],
  data() {
    return {
      flag: false,
      marker: {},
      zoom: 12,
      showList: false,
      infoWindowFlag: false,
      userInfo: {
        id: '',
        realName: '',
        avatar: ''
      },
      winPos:{lng: 116.404, lat: 39.915},
      windowOffset: {
        width: -3,
        height: -12
      },
      center: {lng: 116.404, lat: 39.915},
      initMapFlag: false
    }
  },
  watch:{
    initMap(val,oldVal){
      if(val){
        this.initMapFlag = true
        if(this.polylinesArr.length){
          this.flag = true
          let list = this.polylinesArr[this.polylinesArr.length-1].gpsList
          this.center = {
            lng: list[list.length-1].lng, 
            lat: list[list.length-1].lat
          }
        }else{
          this.flag = false
          this.center = {lng: 116.404, lat: 39.915}
        }
      }
    }
  },
  mounted() {},
  methods: {
    getCenter(m){
      let center = {
        lng: 116.404, lat: 39.915
      }
      if(m.gpsList && m.gpsList.length){
        center = m.gpsList[m.gpsList.length-1]
      }
      return center
    },
    showInfoWindow(m){
      this.infoWindowFlag = true
      this.userInfo = m
      this.winPos = m.gpsList[m.gpsList.length-1]
    },
    closeWindow(){
      this.infoWindowFlag = false
    },
    handleCall(){
      console.log('呼叫.......')
    },
    mapCall(){
      this.$emit('mapCall',this.userInfo)
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/baiduMap.scss';
</style>
